import React, { useState, useMemo, useRef, useEffect } from "react";
import ResponsiveAppBar from './components/appBar.js'
import CircularProgress from '@mui/material/CircularProgress';
import './App.css';
import QRCode from "react-qr-code";
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { ReactComponent as GELogo } from './assets/GElogo.svg';
import SvgIcon from '@mui/material/SvgIcon';
import str from './lang';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { grey, lightBlue, orange } from '@mui/material/colors';
import Grid from '@mui/material/Grid';
import Devices from './components/devices';
import Records from './components/records';
import Configs from './components/configs';
import Summary from './components/summary';
import SnackbarManager from "./components/snackbarManager";

import { useLocation } from 'react-router-dom';
import FileCopyTwoToneIcon from '@mui/icons-material/FileCopyTwoTone';
import IconButton from '@mui/material/IconButton';
import qrTimeout from './assets/qrTimeout.svg';
import Snackbar from '@mui/material/Snackbar';

//export const API_END_POINT = "http://192.168.16.98/api"
export const API_END_POINT = "https://www.griffith-elder.net/api"

export const API_APP_NAME = "datastore";

const myTheme = createTheme({
components: {
	MuiListItem: {
      		styleOverrides: {
        		root: {
          			'&.Mui-selected': {
            				backgroundColor: lightBlue[50],
          			},
        		},
      		},
  	}
  },
  typography: {
      allVariants: {
      color: "black"
    },
  },
  palette: {
    primary: {
      main: lightBlue[50],
    },
  },
});

export const CONTEXT_DASHBOARD = "DASHBOARD";
export const CONTEXT_RECORDS = "RECORDS";
export const CONTEXT_CONFIG = "CONFIG";
export const CONTEXT_ACCOUNT = "ACCOUNT";
export const CONTEXT_ACCESS = "ACCESS";
export const CONTEXT_LOGOUT = "LOGOUT";

export const pages = [ CONTEXT_DASHBOARD, CONTEXT_RECORDS, CONTEXT_CONFIG ];
export const settings = [ CONTEXT_ACCESS, CONTEXT_LOGOUT ];

const uuidv4 = () => {
		return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, c =>
    (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16) );
} 

const TIMER_COUNT = 40;
const TIMER_INTERNAL = 1000;

async function _copyToClipboard(textToCopy) {
    // Navigator clipboard api needs a secure context (https)
    if (navigator.clipboard && window.isSecureContext) {
        await navigator.clipboard.writeText(textToCopy);
    } else {
        // Use the 'out of viewport hidden text area' trick
        const textArea = document.createElement("textarea");
        textArea.value = textToCopy;
            
        // Move textarea out of the viewport so it's not visible
        textArea.style.position = "absolute";
        textArea.style.left = "-999999px";
            
        document.body.prepend(textArea);
        textArea.select();

        try {
            document.execCommand('copy');
        } catch (error) {
            console.error(error);
        } finally {
            textArea.remove();
        }
    }
}



function App() {

	const [context, setContext] = useState( CONTEXT_DASHBOARD );
	const [loading, setLoading] = useState( false );
	const [msg, setMsg] = useState( "" );
	const [jwtDetails, setJWTDetails] = useState({});
	const params = new URL(document.location.toString()).searchParams;
	const aURLToken = params.get("token");
	const _hasURLToken = ( !(  null === aURLToken || undefined === aURLToken  ) );
         const snackbarRef = useRef();
	const _getToken = () => {
  		try{
			const saved = JSON.parse( localStorage.getItem("gehq-token") );
			console.log("_getToken");
			//console.log( saved );
			if ( null == saved ) return uuidv4();
			if ( !( "token" in saved ) ) return uuidv4();
			return saved["token"];
		} catch( e ) {
			return uuidv4();
		}
	};
	
	const _getJWT = () => {
  		const saved = localStorage.getItem("gehq-jwt");
		return saved;
	};

const initialToken = useMemo(() => {
  return _hasURLToken ? aURLToken : _getToken();
}, []);

	const [ auth, setAuth] = useState({
			token: initialToken,
			jwt: _getJWT(),
			currentCount: TIMER_COUNT
		});


	const testAuthorisation = () => {
		if ( !_hasURLToken ) setAuth( prev => ({...prev, currentCount: (prev["currentCount"] - 1 ) }));
		_authorisationCall();
	}

	const _authorisationCall = () => {
                if ( loading ) return;	
		const args = _hasURLToken ? {"urlToken":aURLToken } : {"webToken": auth["token"] };
		setLoading( true );


		console.log("_authorisationCall()" );

		fetch( API_END_POINT , {
			method: "post",
			mode:'cors',
			headers: {	
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
			//make sure to serialize your JSON body
			body: JSON.stringify({
				method:"authentication",
				params: args,
				jsonrpc:"2.0",
				id:Math.floor(Math.random()*1000),
			})
		}).then( (response) => {
			return response.json()
		}).then( (response) => {
			
			console.log("_authorisationCall() RSP" );

			if ( "error" in response ) {
				//eventHandler( response["error"]["message"] );
			} else {
				try {
			
					const a = response["result"].split(".");
					const r = JSON.parse( atob( a[1] ) );
					setJWTDetails( r );
					localStorage.setItem("gehq-jwt",response["result"]);
					localStorage.setItem("gehq-token",
						`${ JSON.stringify({
							"sub": r["sub"],
							"scope": r["scope"],
							"token":auth["token"]
						})}`);
				        setAuth( prev => ({...prev, currentCount: 0, jwt: response["result"] }));
				} catch ( e ) {
					errorHandler( e );
				}

			}
			setLoading( false );
		}).catch( e => {
			console.error(e);
			setLoading( false );
		});
	}

	const errorHandler = ( e ) => {
		if ( e === "not authorised" ||
			e === "re-authenticate") {
	    		console.log(`errorHandler ${e}`);
			setAuth( prev => ({...prev,
		    		currentCount: TIMER_COUNT,
				jwt: null,
				token: uuidv4()
			}));
		}
		console.log( `ERROR ${ e  }` );
	}

	const eventHandler = ( s ) => {
		
 snackbarRef.current.showMessage( s );
		//setMsg( s );
	}

	const Content = () => {
		switch ( context ) {
			case CONTEXT_ACCESS:
				return <Devices str={str} jwt={auth["jwt"]} device={jwtDetails}
					setLoading={setLoading} errorHandler={errorHandler} eventHandler={eventHandler}/>
			case CONTEXT_RECORDS:
				return <Records str={str} jwt={auth["jwt"]} setLoading={setLoading}
					errorHandler={errorHandler} eventHandler={eventHandler}/>
			case CONTEXT_CONFIG:
				return <Configs str={str} jwt={auth["jwt"]} jwtDetails={jwtDetails}
					setLoading={setLoading} errorHandler={errorHandler} eventHandler={eventHandler}/>
			case CONTEXT_DASHBOARD:
				return <Summary str={str} jwt={auth["jwt"]} setLoading={setLoading}
					errorHandler={errorHandler} eventHandler={eventHandler}/>
			default: return <h4>Default</h4>;
		}
	}

	useEffect(
		() => {
		    console.log( `auth change: ${ JSON.stringify( auth, null,2) } `);
		    if ( auth["jwt"] !== null ) return;
		    
		    //START IT OFF
		    if ( auth["currentCount"] === TIMER_COUNT ) {
			testAuthorisation();
		    } else {
		    	if ( auth["currentCount"] <= 0) return;
			const id = setInterval(testAuthorisation, TIMER_INTERNAL );
		    	return () => clearInterval(id);
		    }
		},[auth]
	 );


const MemoizedContent = React.memo(({ auth, str, jwtDetails }) => {
  return <Content auth={auth} str={str} jwtDetails={jwtDetails} />;
});


	if (auth["jwt"] !== null) return (
	<ThemeProvider theme={myTheme}>
    	   <SnackbarManager ref={snackbarRef} />	
		<ResponsiveAppBar setContext={setContext} str={str} />
        	<Box sx={{flexgGrow:1,"m":5}}>
			<Content />
		</Box>
	</ThemeProvider >
  );

  const jsonStr = JSON.stringify({"webToken":auth["token"]});

  return (
	<div className="App">
	  <Box component="span" sx={{m:"8em",px:"10px",maxWidth:"sm",display:"inline-block"}}>
    		<Card sx={{ minWidth: 275 }}>
      			<CardContent>
        			<Typography sx={{color:"inherit",fontWeight: 300,mb:"10px"}}>
 					<SvgIcon sx={{ display: "inline-block", mr: 1 }} >
          					<GELogo />
	  				</SvgIcon>
	  				{ str("QR_GRANT_ACCESS") }
        			</Typography>
	  			{ auth["currentCount"] > 0 ?
				<><QRCode value={ jsonStr } />
		  			<Grid container alignItems="center" >
						<Grid item xs >
							<Box textAlign="center">
				{ `${ JSON.stringify({'webToken':auth["token"]}).substring(0,25) }...` }
							</Box>		
						</Grid>
					<Grid item >
		  		<IconButton aria-label="copy"
					onClick={() => _copyToClipboard(jsonStr) } >
					<FileCopyTwoToneIcon />
				</IconButton>
			</Grid>
		</Grid>
		</> : <img src={ qrTimeout } /> }
	  	</CardContent>
	  </Card>
	  </Box>
	</div>
  );

}

export default App;

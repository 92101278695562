//const str = ( s , locale = 'en' ) => ( s in STRINGS) ? ( (locale in STRINGS[s]) ? STRINGS[s][locale] : '. . .') : ". . .";
const str = ( s , locale = 'en' ) => {
    if ( navigator ){
    }
    
    if ( s in STRINGS) {
        if (locale in STRINGS[s]) return STRINGS[s][locale];
        else {
            return '. . .';
        }
     } else {
         return ". . .";
     }
}

const STRINGS = {
    "QR_GRANT_ACCESS" : {
        "en" : "Cloud access"
    },
     "TITLE" : {
        "en" : "Cloud"
    },
    "DASHBOARD" : {
        "en" : "Dashboard"
    },
    "RECORDS" : {
        "en" : "Records"
    },
    "ACCOUNT":{
        "en": "Account"
    },
    "CONFIG":{
	"en":"Configuration"
    },
    "CONFIG_UPLOADS":{
	"en":"Configuration Uploads"
    },
    "ACCESS":{
        "en": "Access"
    },
    "LOGOUT":{
        "en": "Logout"
    },
    "DEVICE_MANAGEMENT":{
    	"en":"Device Access"
    },
    "DEVICE_ID":{
	"en":"Device ID"
    },
    "mfrView":{
	"en":"Manufacturer view"
    },
   "mfr":{
	"en":"Manufacturer"
    },
    "usr":{
	"en":"User",
    },
    "3rd":{
	"en":"3rd Party"
    },
    "DELETE":{
        "en": "Delete"
    },
    "ACCESS_TYPE":{
	"en":"Type"
    },
    "RECORD_UPLOADS":{
	"en":"Record Uploads"
    },
    "RECORD_MANAGEMENT":{
	"en":"Records"
    },
    "WEIGHING_DEVICE":{
	"en":"Scale"
    },
    "DEVICE_SERIAL":{
	"en":"Serial"
    },
    "RECORD_UUID": {
	"en":"Unique ID"
    },
    "RECORD_ORIGIN": {
	"en":"Origin"
    },
    "RECORD_TIME": {
	"en":"Time"
    },
    "RECORD_WEIGHT": {
	"en":"Weight"
    },
    "RECORD_GROSS": {
	"en":"Gross"
    },
    "RECORD_NET": {
	"en":"Net"
    },
    "RECORD_TARE": {
	"en":"Tare"
    },
    "RECORD_REFERENCE": {
	"en":"Reference"
    },
 "TABLE_SETTING":{
	"en":"Setting"
    },
    "TABLE_VALUE":{
    	"en":"Value"
    },
    "SUMMARY":{
	"en":"Summary"
    },
    "TABLE_INFO":{
	"en":"Info"
    },
    "ENTITY_NAME":{
    	"en":"Company"
    },
    "DEVICE_COUNT":{
	"en":"Devices"
    },
    "RECORD_COUNT":{
	"en":"Records"
    },
    "CONFIG_COUNT":{
	"en":"Configs"
    },
    "NO_UPLOADS":{
	"en":"No Uploads"
    },
    "ACTION":{
	"en":"Action"
    },
    "ACTION_MERGE":{
	"en":"Merge"
    },
    "ACTION_DELETE":{
	"en":"Delete"
    },
    "ACTION_NONE":{
	"en":"None"
    },
 "CANCEL":{
	"en":"Cancel"
    },
 "ACTION_FAILED":{
	"en":"Action Failed"
    },
	"DELETE_CONFIRM": {
	   "en": "Are you sure you want to delete: "
	},

	"ERR_RE_AUTH_FAILED":{
	"en":"Re-Authentication Failed"
	},
	"FILTER_OPEN_FROM":{
	"en":"Open From"
	},
	"FILTER_OPEN_TO":{
	"en":"Open to"
	},
	"CLEAR":{
"en": "clear"
	}
	,"CONFIRM":{
"en": "Confirm"
	},
	"ARE_YOU_SURE":{
	"en":"Are you sure you want to"
	}
}
;

export default str;

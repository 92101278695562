import React, { useState, useCallback } from "react";
import Snackbar from "@mui/material/Snackbar";

const SnackbarManager = React.forwardRef((props, ref) => {
  const [snackbar, setSnackbar] = useState({ open: false, message: "" });

  const showMessage = useCallback((message) => {
    setSnackbar({ open: true, message });
  }, []);

  const closeSnackbar = () => {
    setSnackbar((prev) => ({ ...prev, open: false }));
  };

  // Expose the `showMessage` function to the parent via ref
  React.useImperativeHandle(ref, () => ({
    showMessage,
  }));

  return (
    <Snackbar
      open={snackbar.open}
      autoHideDuration={3000}
      onClose={closeSnackbar}
      message={snackbar.message}
    />
  );
});

export default SnackbarManager;


import React, { useEffect } from "react";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Grid from '@mui/material/Grid';
import DevicesIcon from '@mui/icons-material/Devices';
import Input from '@mui/material/Input';
import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';

import { API_END_POINT } from '../App';

function Device( props ) {


	const voidDevice = ( deviceID ) => {
	        console.log( "\t\tvoidDevice() called");	
		//props.callback
		const auth = `Bearer ${props.jwt}`
		fetch( API_END_POINT , {
			method: "post",
			mode:'cors',
			headers: {	
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authentication': auth
			},
			//make sure to serialize your JSON body
			body: JSON.stringify({
				method:  "voidDevice",
				params: [ deviceID ],
				jsonrpc:"2.0",
				id:Math.floor(Math.random()*1000),
			})
		}).then( (response) => {
			return response.json()
		}).then( (response) => {
			if ( "error" in response ) {
			console.log("error in response");	

			if ( "re-authenticate" === response["error"]["message"] ) {
				props.reAuthenticate().then( r => {
					if ( r ) voidDevice( deviceID );
				}).catch( e => props.errorHandler( props.str("ERR_RE_AUTH_FAILED") ) );
			} else props.errorHandler( response["error"]["message"] );
	


			} else {
				try {
					console.log( `voidDevice() result = ${ response["result"] }` );
					if ( response["result"] === 1 ) {
						props.callback( deviceID  );
						props.eventHandler( "OK" );
					} else {
						props.eventHandler( "Failed" );
					}
				} catch ( e ) {
					props.errorHandler( e );
				}
				console.log( response );
			}
		}).catch( e => {
			console.error(e);
		});
	}

	return(
		<Grid container direction={"column"} spacing={2} > 
		  <Grid item >
		 	<Input disabled defaultValue={ props.dev["deviceID"] } label={ props.str("DEVICE_ID") } />
		  </Grid>
		  <Grid item>
			<Input disabled defaultValue={ props.str( props.dev["type"] ) } label={ props.str("ACCESS_TYPE") } />
		  </Grid>
		  <Grid item justifyContent="flex-end">
		  	<Button color="secondary"
				onClick={ e => voidDevice( props.dev["deviceID"] ) }
				startIcon={<DeleteIcon />}>
				{ props.str("DELETE") }
      			</Button>
		  </Grid>
		</Grid>
	);
}

function Devices( props ) {
  const [loading, setLoading] = React.useState(false);
  const [devices, setDevices] = React.useState([]);
  


  const _getDevices = () => {
	if ( loading ) return;
	setLoading( true );
	console.log( "_getDevices() called ");
	const auth = `Bearer ${props.jwt}`
	
	fetch( API_END_POINT , {
		method: "post",
		mode:'cors',
		headers: {	
			'Accept': 'application/json',
			'Content-Type': 'application/json',
			'Authentication': auth
		},
		//mak sure to serialize your JSON body
		body: JSON.stringify({
			method:"getDevices",
			params: [ ],
			jsonrpc:"2.0",
			id:Math.floor(Math.random()*1000),
		})
	}).then( (response) => {
		return response.json()
	}).then( (response) => {
		setLoading( false );
		if ( "error" in response ) {
			props.errorHandler( response["error"]["message"] );
		} else {
			try {
				console.log(`rsp size: ${ response["result"].length }` );
				setDevices( response["result"] );
			} catch ( e ) {
				props.errorHandler( e );
			}

			//console.log( response );
		}
	}).catch( e => {
		setLoading( false );
		console.error(e);
	});

	}

  useEffect(
        () => {
		if ( devices.length === 0 && !loading) {
			_getDevices();
		}
        },[devices,loading,_getDevices]);


	//console.log( props.device );

	return(
	<Grid container direction={"column"} spacing={2} >	
		<Grid item >
			{ props.str("DEVICE_MANAGEMENT") }
		</Grid>
		<Grid item >
	  		{ devices.map( (dev,i) => (
				<Accordion key={dev["deviceID"]}>
					<AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
					  <Grid container direction={"row"} spacing={2} >
					  <Grid item xs={1}>
						<DevicesIcon color={ dev["id"] === props.device["sub"] ? "warning" : "success" } />
					  </Grid>
					  <Grid item xs={6}>
						<Typography sx={{ overflow:'hidden', textOverflow:'ellipsis', whiteSpace:'nowrap' }} >
							{ dev["name"] }
						</Typography>
					  </Grid>
					  <Grid item xs={2}>
						{ props.str( dev["type"] ) }
					  </Grid>
					  <Grid item xs={3}>
						{ dev["open_date"] }  
					</Grid>
					</Grid>
					</AccordionSummary>
					<AccordionDetails>
					<Device 
						key={`dev-${dev["deviceID"]}`}
						str={ props.str }
						dev={ dev }
						jwt={ props.jwt }
						errorHandler={ props.errorHandler }
						eventHandler={ props.eventHandler }
						callback={ d => {
							console.log(`Device.callback() -> devices.length: ${devices.length}` );
							const newList =  devices.filter((d) =>{
								return d["deviceID"] !== dev["deviceID"]; });
							

							console.log(`Device.callback() -> newList: ${newList.length}` );
							setDevices( newList );
						} } />
					</AccordionDetails>
      				</Accordion>
			)) }
		</Grid>
	</Grid>
	);
}

export default Devices;
